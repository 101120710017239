import { MODEL_DATA, MODEL_EDIT_DATA, DELETE_DUPLICATE_DATA } from "../actions/types";
const initialState = {
    modelData: {},
    modelEditData: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case MODEL_DATA:
            return {
                ...state,
                modelData: action.payload
            };

        case MODEL_EDIT_DATA:
        case DELETE_DUPLICATE_DATA:
            return {
                ...state,
                modelEditData: action.payload
            };


        default:
            return state;
    }
}
