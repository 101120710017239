import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const { routes, pageConfig } = this.props;
        return (
            <header className="container-fluid m-0 p-0 navbar-dark bg-dark text-light">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <a className="navbar-brand" href="/"><img src={"https://www.logicalsites.com" + pageConfig.companyLogo} alt={pageConfig.companyName} /> {pageConfig.companyName}</a>
                        </div>
                        <div className="col-6 text-right pt-2">
                            Phone: <a className="text-white" href={"tel:"+pageConfig.officePhone}>{pageConfig.officePhone}</a>
                        </div>
                    </div>
                    <div className="row px-0">
                        <div className="col-12 px-0">
                            <nav className="navbar navbar-expand-lg">
                                <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" />
                                </button>

                                <div className={`${classOne}`} id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto mr-auto">
                                        {routes.map((route, index) => {
                                            return (<li key={index} className="nav-item"><Link className="nav-link" title={"Navigation link to " + route.name} onClick={this.toggleNavbar} to={route.linkUrl}>{route.name}</Link></li>);
                                        })}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header