import { GET_TRUCK_MAKES, ADD_TRUCK_MAKE, GET_TRUCK_MAKE, UPDATE_TRUCK_MAKE, DELETE_TRUCK_MAKE } from "../actions/types";
const initialState = {
    makes: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_TRUCK_MAKES:
            return {
                ...state,
                makes: action.payload
            };

        case GET_TRUCK_MAKE:
            return {
                ...state,
                makes: action.payload
            };

        case ADD_TRUCK_MAKE:
            return {
                ...state,
                makes: action.payload
            };

        case UPDATE_TRUCK_MAKE:
            return {
                ...state,
                makes: action.payload
            };

        case DELETE_TRUCK_MAKE:
            return {
                ...state,
                makes: action.payload
            };

        default:
            return state;
    }
}