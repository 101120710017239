import {
    GET_DATA_CATEGORIES,
    GET_DATA_CATEGORY,
    GET_DATA_CATEGORY_LIST,
    ADD_DATA_CATEGORY,
    UPDATE_DATA_CATEGORY,
    DELETE_DATA_CATEGORY
} from "../actions/types";
const initialState = {
    data: {},
    categoryList: {},
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DATA_CATEGORIES:
            return {
                ...state,
                data: action.payload
            };

        case GET_DATA_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload
            };

        case GET_DATA_CATEGORY:
            return {
                ...state,
                data: action.payload
            };

        case ADD_DATA_CATEGORY:
            return {
                ...state,
                data: action.payload
            };

        case UPDATE_DATA_CATEGORY:
            return {
                ...state,
                data: action.payload
            };

        case DELETE_DATA_CATEGORY:
            return {
                ...state,
                data: action.payload
            };

        default:
            return state;
    }
}