import React, {Component} from 'react';

class Footer extends Component {
    render() {
        const { pageConfig } = this.props;
        if (pageConfig !== null)
        {
            return (
                <footer className="text-white mt-0 container-fluid" style={{backgroundColor: "#630000"}}>
                    <div className="container">
                        <div className="row">
                            <div className="py-3 small col-6">
                                <p className="p-0 m-0" dangerouslySetInnerHTML={{__html: "&copy; " + pageConfig.copyright}} />
                            </div>
                            <div className="py-3 small col-6 text-right">
                                <a href="/contact-us" className="btn btn-sm btn-light">Email Us</a>
                            </div>
                        </div>
                    </div>
                </footer>
            )
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

export default Footer;