import { GET_USERS, ADD_USER, GET_USER, UPDATE_USER, DELETE_USER } from "../actions/types";
const initialState = {
    users: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            };

        case ADD_USER:
            return {
                ...state,
                users: action.payload
            };

        case GET_USER:
            return {
                ...state,
                users: action.payload
            };

        case UPDATE_USER:
            return {
                ...state,
                users: action.payload
            };

        case DELETE_USER:
            return {
                ...state,
                users: action.payload
            };

        default:
            return state;
    }
}