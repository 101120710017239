import React, {Component, Fragment} from 'react';
import {Parallax} from "react-parallax";
import imageBackground2 from "../../assets/images/bg-2.jpg";

class FeaturedBoxes extends Component {
    render() {
        const { title, list } = this.props;
        return (
            <Fragment>
                <div className="container-fluid mx-0 px-0">
                    <Parallax
                        bgImage={imageBackground2}
                        bgImageAlt={title}
                        strength={700}
                        blur={{ min: -15, max: 15 }}
                    >
                        <div className="container my-4 py-4">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="h1 py-4 my-4 text-center">{title ? title : ''}</h2>
                                </div>
                            </div>
                        </div>
                        {list && list > 0 && list.map((content, index) => {
                            return (<div key={index} className="container p-4 bg-white my-4">
                                <div className="row">
                                    <div className="col-sm-8 p-4">
                                        <h3 className="h1 my-4" style={{fontFamily: 'ZCOOL XiaoWei'}}>{content.name}</h3>
                                        <div dangerouslySetInnerHTML={{__html: content.description}} />
                                        <a className="btn btn-primary" href={content.linkUrl} target="_blank" rel="noopener noreferrer" style={{fontFamily: 'ZCOOL XiaoWei'}}>{content.linkText}</a>
                                    </div>
                                    <div className="col-sm-4 p-4">
                                        <img src={"https://www.logicalsites.com" + content.image} alt={content.name} className="img-fluid" />
                                    </div>
                                </div>
                            </div>);
                        })}
                    </Parallax>
                </div>
            </Fragment>
        )
    }
}

export default FeaturedBoxes
