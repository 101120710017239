import { GET_IMPORT_MODELS, GET_EXPORT_MODELS, PROCESS_IMPORT } from "../actions/types";
const initialState = {
    importexport: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_EXPORT_MODELS:
            return {
                ...state,
                importexport: action.payload
            };

        case GET_IMPORT_MODELS:
            return {
                ...state,
                importexport: action.payload
            };

        case PROCESS_IMPORT:
            return {
                ...state,
                importexport: action.payload
            };

        default:
            return state;
    }
}