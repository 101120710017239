import { GET_TRUCK_SEGMENTS, ADD_TRUCK_SEGMENT, GET_TRUCK_SEGMENT, UPDATE_TRUCK_SEGMENT, DELETE_TRUCK_SEGMENT } from "../actions/types";
const initialState = {
    segments: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_TRUCK_SEGMENTS:
            return {
                ...state,
                segments: action.payload
            };

        case ADD_TRUCK_SEGMENT:
            return {
                ...state,
                segments: action.payload
            };

        case GET_TRUCK_SEGMENT:
            return {
                ...state,
                segments: action.payload
            };

        case UPDATE_TRUCK_SEGMENT:
            return {
                ...state,
                segments: action.payload
            };

        case DELETE_TRUCK_SEGMENT:
            return {
                ...state,
                segments: action.payload
            };

        default:
            return state;
    }
}