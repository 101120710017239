import { combineReducers } from 'redux';
import pagesReducer from './pagesReducer';
import yearsReducer from './yearsReducer';
import usersReducer from './usersReducer';
import makesReducer from './makesReducer';
import modelsReducer from './modelsReducer';
import segmentsReducer from './segmentsReducer';
import feedbackReducer from './feedbackReducer';
import configReducer from './configReducer';
import comparisonsReducer from './comparisonsReducer';
import importExportReducer from './importExportReducer';

import auditDataReducer from "./auditDataReducer";
import authReducer from "./authReducer";

import benefitCategoriesReducer from './benefitCategoriesReducer';
import dataCategoriesReducer from './dataCategoriesReducer';

export default combineReducers({
    benefits: benefitCategoriesReducer,
    comparisons: comparisonsReducer,
    config: configReducer,
    data: dataCategoriesReducer,
    feedbacks: feedbackReducer,
    makes: makesReducer,
    models: modelsReducer,
    results: pagesReducer,
    segments: segmentsReducer,
    users: usersReducer,
    years: yearsReducer,
    importexport: importExportReducer,
    modeldata: auditDataReducer,
    auth: authReducer
})
