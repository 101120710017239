import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import axios from "axios";
import {apiKey, apiURL} from "../../config";
import AdditionalContent from "../AdditionalContent/AdditionalContent";
import {Parallax} from "react-parallax";
import headerImage from "../../assets/images/header2.jpg";

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: []
        };
        this.handleFlipClick = this.handleFlipClick.bind(this);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.getContent();
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getContent();
    }

    getContent = () => {
        axios.get(apiURL + 'getPage', {params: {apiKey: apiKey, path: this.props.match.path}})
            .then( (response) => {
                if (typeof(response.data.page) !== 'undefined') {
                    this.setState({
                        "content": response.data.page
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleFlipClick = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    };

    render() {
        const { content } = this.state;
        if (typeof(content.id) === 'undefined') {
            return(null);
        } else {
            return (
                <Fragment>
                    <Helmet>
                        <title>{content.metaTitle}</title>
                        <meta name="description" content={content.metaDescription} />
                        <meta name="robots" content={content.metaRobots} />
                        <meta name="googlebot" content={content.metaGoogleBot} />
                        <meta name="keywords" content={content.metaKeywords} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>
                    <Parallax
                        bgImage={headerImage}
                        bgImageAlt={"Header Image"}
                        strength={700}
                        blur={{ min: -15, max: 15 }}
                    >
                        <div className="container my-4 py-4">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center text-white my-5 text-uppercase">{content.pageTitle}</h1>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                    <div className="container-fluid bg-light py-5">
                        <div className="container rounded bg-white py-5">
                            <div className="row">
                                <div className="col-12">
                                    <div dangerouslySetInnerHTML={{__html: content.pageContent}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdditionalContent
                        additionalContent={content.additionalContent.split(";")}
                        additionalContentList={content.additionalContentList}
                        featuredBoxesList={content.featuredBoxesList}
                    />

                </Fragment>
            );
        }
    }
}

export default ContentPage