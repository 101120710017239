import { GET_COMPARISONS, GET_COMPARISON, ADD_COMPARISON, UPDATE_COMPARISON, DELETE_COMPARISON } from "../actions/types";
const initialState = {
    comparisons: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_COMPARISONS:
            return {
                ...state,
                comparisons: action.payload
            };

        case GET_COMPARISON:
            return {
                ...state,
                comparisons: action.payload
            };

        case ADD_COMPARISON:
            return {
                ...state,
                comparisons: action.payload
            };

        case UPDATE_COMPARISON:
            return {
                ...state,
                comparisons: action.payload
            };

        case DELETE_COMPARISON:
            return {
                ...state,
                comparisons: action.payload
            };

        default:
            return state;
    }
}