export const API_URL  = 'https://factqa.freightlinertrucks.com/apicp';

// DASHBOARD FUNCTIONS
export const GET_TABLE_DATA = 'GET_TABLE_DATA';

// YEARS
export const GET_TRUCK_YEARS = 'GET_TRUCK_YEARS';
export const GET_TRUCK_YEAR = 'GET_TRUCK_YEAR';
export const ADD_TRUCK_YEAR = 'ADD_TRUCK_YEAR';
export const UPDATE_TRUCK_YEAR = 'UPDATE_TRUCK_YEAR';
export const DELETE_TRUCK_YEAR = 'DELETE_TRUCK_YEAR';

// MAKES
export const GET_TRUCK_MAKES = 'GET_TRUCK_YEARS';
export const GET_TRUCK_MAKE = 'GET_TRUCK_YEAR';
export const ADD_TRUCK_MAKE = 'ADD_TRUCK_YEAR';
export const UPDATE_TRUCK_MAKE = 'UPDATE_TRUCK_YEAR';
export const DELETE_TRUCK_MAKE = 'DELETE_TRUCK_YEAR';

// MODELS
export const GET_TRUCK_MODELS = 'GET_TRUCK_MODELS';
export const GET_TRUCK_MODEL_FILTER = 'GET_TRUCK_MODEL_FILTER';
export const GET_TRUCK_MODEL = 'GET_TRUCK_MODEL';
export const ADD_TRUCK_MODEL = 'ADD_TRUCK_MODEL';
export const UPDATE_TRUCK_MODEL = 'UPDATE_TRUCK_MODEL';
export const DELETE_TRUCK_MODEL = 'DELETE_TRUCK_MODEL';

// MODEL DATA (AUDIT DATA TOOL)
export const MODEL_DATA = 'MODEL_DATA';
export const MODEL_EDIT_DATA = 'MODEL_EDIT_DATA';
export const DELETE_DUPLICATE_DATA = 'DELETE_DUPLICATE_DATA';

// CATEGORIES : BENEFIT
export const GET_BENEFIT_CATEGORIES = 'GET_BENEFIT_CATEGORIES';
export const GET_BENEFIT_CATEGORY_LIST = 'GET_BENEFIT_CATEGORY_LIST';
export const GET_BENEFIT_CATEGORY = 'GET_BENEFIT_CATEGORY';
export const ADD_BENEFIT_CATEGORY = 'ADD_BENEFIT_CATEGORY';
export const UPDATE_BENEFIT_CATEGORY = 'UPDATE_BENEFIT_CATEGORY';
export const DELETE_BENEFIT_CATEGORY = 'DELETE_BENEFIT_CATEGORY';

// CATEGORIES : DATA
export const GET_DATA_CATEGORIES = 'GET_DATA_CATEGORIES';
export const GET_DATA_CATEGORY_LIST = 'GET_DATA_CATEGORY_LIST';
export const GET_DATA_CATEGORY = 'GET_DATA_CATEGORY';
export const ADD_DATA_CATEGORY = 'ADD_DATA_CATEGORY';
export const UPDATE_DATA_CATEGORY = 'UPDATE_DATA_CATEGORY';
export const DELETE_DATA_CATEGORY = 'DELETE_DATA_CATEGORY';

// SEGMENTS
export const GET_TRUCK_SEGMENTS = 'GET_TRUCK_SEGMENTS';
export const GET_TRUCK_SEGMENT = 'GET_TRUCK_SEGMENT';
export const ADD_TRUCK_SEGMENT = 'ADD_TRUCK_SEGMENT';
export const UPDATE_TRUCK_SEGMENT = 'UPDATE_TRUCK_SEGMENT';
export const DELETE_TRUCK_SEGMENT = 'DELETE_TRUCK_SEGMENT';

// PAGES
export const GET_CONFIG = 'GET_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const GET_PUBLISH_SITE = 'GET_PUBLISH_SITE';

// FEEDBACK
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';

// USERS
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// IMPORT / EXPORT
export const GET_IMPORT_MODELS = 'GET_IMPORT_MODELS';
export const GET_EXPORT_MODELS = 'GET_EXPORT_MODELS';
export const PROCESS_IMPORT = 'PROCESS_IMPORT';

// COMPARISONS
export const GET_COMPARISONS = 'GET_COMPARISONS';
export const GET_COMPARISON = 'GET_COMPARISON';
export const ADD_COMPARISON = 'ADD_COMPARISON';
export const UPDATE_COMPARISON = 'UPDATE_COMPARISON';
export const DELETE_COMPARISON = 'DELETE_COMPARISON';

// USER AUTH
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';
