import { GET_TRUCK_MODELS, ADD_TRUCK_MODEL, GET_TRUCK_MODEL, UPDATE_TRUCK_MODEL, DELETE_TRUCK_MODEL, GET_TRUCK_MODEL_FILTER } from "../actions/types";
const initialState = {
    models: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_TRUCK_MODELS:
            return {
                ...state,
                models: action.payload
            };

        case GET_TRUCK_MODEL:
            return {
                ...state,
                models: action.payload
            };

        case GET_TRUCK_MODEL_FILTER:
            return {
                ...state,
                models: action.payload
            };

        case ADD_TRUCK_MODEL:
            return {
                ...state,
                models: action.payload
            };

        case UPDATE_TRUCK_MODEL:
            return {
                ...state,
                models: action.payload
            };

        case DELETE_TRUCK_MODEL:
            return {
                ...state,
                models: action.payload
            };

        default:
            return state;
    }
}