import { GET_BENEFIT_CATEGORIES, GET_BENEFIT_CATEGORY_LIST, GET_BENEFIT_CATEGORY, ADD_BENEFIT_CATEGORY, UPDATE_BENEFIT_CATEGORY, DELETE_BENEFIT_CATEGORY } from "../actions/types";
const initialState = {
    benefits: {},
    categoryList: {},
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_BENEFIT_CATEGORIES:
            return {
                ...state,
                benefits: action.payload
            };

        case GET_BENEFIT_CATEGORY:
            return {
                ...state,
                benefits: action.payload
            };

        case GET_BENEFIT_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload
            };

        case ADD_BENEFIT_CATEGORY:
            return {
                ...state,
                benefits: action.payload
            };

        case UPDATE_BENEFIT_CATEGORY:
            return {
                ...state,
                benefits: action.payload
            };

        case DELETE_BENEFIT_CATEGORY:
            return {
                ...state,
                benefits: action.payload
            };

        default:
            return state;
    }
}